@import "../shared/responsive-vars";
@import "product-label-vars";
@import "wishlist-icon";
@import "product-services-banner";
@import "personalized-services-search";

$price-color: #000;
$old-price-color: #2196f3;
$add-to-cart-color: #000;

@media (max-width: $break-md) {
 #stickersList {
   margin-top: 15px
 }
}

/*.list-item {
  transition: width 0.155s ease-in, heigth 0.155s ease-in;
}*/

@mixin small-size-product($inClass:true, $important:null) {
  .title {
    width: 100% $important;
    display: block $important;
    line-height: unset $important;
    height: unset $important;
    float: none $important;
    text-align: center $important;
    overflow: hidden $important;
    -ms-text-overflow: ellipsis $important;
    text-overflow: ellipsis $important;
    white-space: nowrap $important;

    a {
      color: #000 $important;
      font-weight: bold $important;
      text-decoration: none $important;
      font-size: 14px $important;

      @if $inClass {
        font-size: 10px $important;
        @media (min-width: $break-md) {
          font-size: 11px $important;
        }
      }
    }
  }

  .price {
    width: 100% $important;
    display: block $important;
    line-height: unset $important;
    height: unset $important;
    float: none $important;
    text-align: center $important;

    .old {
      color: $old-price-color $important;
      text-decoration: line-through $important;
      font-size: 12px $important;
      margin-right: 10px $important;
      display: inline-block $important;

      @if $inClass {
        @media (min-width: $break-md) {
          font-size: 10px $important;
        }
      }
    }

    .current {
      display: inline-block $important;
      span {
        color: $old-price-color $important;
        font-weight: bold $important;
        font-size: 14px $important;

        @if $inClass {
          @media (min-width: $break-md) {
            font-size: 12px $important;
          }
        }
      }
    }
  }
}

.list-product {
  width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.with-margin-bottom {
    margin-bottom: 30px;
  }

  /*&:hover {
    .slick-current .extra-image.first {
      -moz-transform: scale(1.03);
      -webkit-transform: scale(1.03);
      -o-transform: scale(1.03);
      -ms-transform: scale(1.03);
      transform: scale(1.03);
    }
  }*/

  .image-box {
    overflow: hidden;
    margin-bottom: 5px;

    img {
      width: 100%;
      height: auto;
      &.swiper-lazy:not(.swiper-lazy-loaded) {
        color: #fff !important;
        /*height: 192px;*/
      }
    }

    /*.slick-current .extra-image.first {
      -webkit-transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
      -ms-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;
    }*/
  }

  &.tooltip-description {
    .labels {
      margin-top: -90px !important;
    }
  }

  .labels {
    height: 21px;
    margin-bottom: 9px;
    text-align: left;
    margin-top: -40px;
    position: absolute;

    &.top {
      margin-bottom: 0;
      margin-top: 9px !important;
      top: 0;
    }

    span {
      font-size: 9px;
      color: #FFF;
      font-weight: bold;
      padding: 3px 4px;
      margin-right: 3px;
      vertical-align: middle;
    }

    .discount {
      background-color: $discount-label-bg;
    }
    .black-discount {
      background-color: $black-discount-label-bg;
    }
    .new {
      background-color: $new-label-bg;
    }
    .best-seller {
      background-color: $best-seller-label-bg;
    }
    .personalizable {
      background-color: $personalizable-label-bg;
    }
    .personalizableImage {
      background-color: $personalizable-label-bg;
      .label-icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: initial;
        width: 18px;
        height: 20px;
        display: inline-block;
        &.image-pers {
          background-image: url(../../images/web/product/pers-image-only.png);
          background-size: initial;
        }
      }
    }
    .free-shipping {
      background-color: $free-shipping-label-bg;
    }
    .personalized-service {
      background-color: #d8b545;
      color: #000;
      text-transform: uppercase;
    }
  }

  &:not(.list-product-small) {
    .labels {
      span {
        font-size: 8px;
        @media (min-width: $break-md) {
          font-size: 12px;
        }
      }
    }

    .title {
      width: 50%;
      text-align: left;
      display: inline-block;
      line-height: 1.2em;
      height: 2.6em;
      float: left;
      overflow: hidden;

      a {
        color: #000;
        font-weight: bold;
        text-decoration: none;
        font-size: 14px;

        @media (min-width: $break-md) {
          font-size: 12px;
        }
      }
    }

    .price {
      width: 50%;
      text-align: right;
      display: inline-block;
      height: 2.6em;

      .old {
        color: $old-price-color;
        text-decoration: line-through;
        font-size: 12px;
        display: block;

        @media (min-width: $break-md) {
          font-size: 10px;
        }
      }

      .current {
        display: block;

        span {
          color: $price-color;
          font-weight: bold;
          font-size: 14px;

          @media (min-width: $break-md) {
            font-size: 12px;
          }
        }
      }
    }
  }

  &.list-product-small {
    @include small-size-product(true, !important);
  }

  @media (max-width: $break-xs) {
    &:not(.list-product-small) {
      @include small-size-product(false, !important);
    }
  }

  .list-add-to-cart, .list-add-to-cart-link {
    border: 1px solid;
    text-transform: none;
    color: $add-to-cart-color !important;
    font-weight: bold;
    margin-bottom: 30px;

    .list-cart-icon {
      color: inherit;
      font-size: 20px;
      line-height: 0;
      vertical-align: sub;
    }
    .listingAddCartLabel {
      text-transform: none;
      color: $add-to-cart-color !important;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  @media (max-width: $break-xs)  {
    .btn.listingAddCart {
      padding: 8px;
    }
  }
  @media (max-width: 320px)  {
    .btn.listingAddCart {
      padding: 8px 3px;
      .listingAddCartLabel, .list-cart-icon {
        font-size: 11px;
      }
      .list-cart-icon {
        vertical-align: middle;
      }
    }
  }

  .swiper-container {
    z-index: 0;
    .swiper-slide {
      padding: 1px !important;
      img.swiper-lazy:not(.swiper-lazy-loaded) {
        color: #fff !important;
        /*height: 30px;*/
      }
    }
    .swiper-button-next, .swiper-button-prev {
      cursor: pointer;
      transition: all .3s ease;
      z-index: 1;
      color: #000;
      opacity: 0;
      &:after {
        font-family: Glyphicons Halflings;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        font-size: 30px;
      }
      &:hover {
        color: #f7ae1c;
      }
      @media (max-width: $break-sm) {
        opacity: 0.9;
      }
    }
    .swiper-button-next:after {
      content: "\E258" !important;
    }
    .swiper-button-prev:after {
      content: "\E257" !important;
    }
  }

  &.list-product-small {
    .swiper-container {
      .swiper-button-next {
        @media (max-width: $break-sm) {
          right: 0;
        }
      }
      .swiper-button-prev {
        @media (max-width: $break-sm) {
          left: 0;
        }
      }
    }
  }

  .image_carousel {
    .slick-slide {
      padding: 1px !important;
    }

    div.extra-image:not(.first) img {
      position: relative;
      margin: 25% auto -100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 25%;
      transform: translateY(-25%);
    }
  }

  .img-carousel-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    cursor: pointer;
    transition: all .3s ease;
    z-index: 1;
    font-size: 30px;
    color: #000;
    opacity: 0;

    span {
      font-size: 30px;
      color: #000;
    }

    span:hover {
      color: #f7ae1c;
    }

    &.img-carousel-prev {
      left: 10px;
    }

    &.img-carousel-next {
      right: 10px;
    }

    @media (max-width: $break-sm) {
      opacity: 0.9;
      top: 45%;
      &.img-carousel-prev {
        padding: 30px 30px 10px 0;
      }
      &.img-carousel-next {
        padding: 30px 0 10px 30px;
      }
    }
  }

  &:hover {
    .swiper-button-next, .swiper-button-prev {
      opacity: 0.9;
    }
  }
}

@media (max-width: $break-sm) {
  .list-item.col-xs-6 {
    .list-product .swiper-container {
      .swiper-button-prev, .swiper-button-next {
        &:after {
          font-size: 1.3em;
        }
      }
    }
  }
}

.product-minimum-version
{
  margin-bottom: 15px;
}

.img-composition-container
{
  width: 100px;
  display: inline-block;
  height: 40px;

  .img-composition {
    max-width: 100%;
    max-height: 50px;
  }
}