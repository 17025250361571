@import "../shared/responsive-vars";

@mixin get-service-icon($x, $y, $width, $height) {
  background-position: $x $y;
  width: $width;
  height: $height;
}

#product-services-banner {
  margin: 20px -15px 0;
  padding: 25px 0;
  background-color: #d8b545;
  text-align: center;

  .product-services-text {
    color: #000;
    font-size: 13px;
  }

  #product-services {
    .product-service {
      cursor: pointer;
      float: left;
      width: 20%;
      transition: transform .2s;
      text-decoration: none;

      &:hover {
        transform: scale(1.1);
      }

      @media (max-width: $break-xs)  {
        width: 50%;

        &:last-child {
          display: none;
        }
      }

      .service-icon {
        background-image: url(../../images/web/personalizedServices/banner/sprite.png);
        background-repeat: no-repeat;
        display: block;
        margin: 0 auto;

        &.service-icon_all {
          @include get-service-icon(-3px, -3px, 100px, 100px);
        }

        &.service-icon_design {
          @include get-service-icon(-109px, -3px, 100px, 100px);
        }

        &.service-icon_rug {
          @include get-service-icon(-3px, -109px, 100px, 100px);
        }

        &.service-icon_sticker {
          @include get-service-icon(-109px, -109px, 100px, 100px);
        }

        &.service-icon_txt {
          @include get-service-icon(-215px, -3px, 100px, 100px);
        }
      }

      .service-name {
        color: #000;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  .btn-view-all-services {
    margin-top: 30px;
  }
}