.priority-service {
  margin: 15px 0;
  line-height: 1.25;

  .priority-service-check {
    border: 1px solid #dee2e6!important;
    padding: .5rem!important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

    .form-check {
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5em;
      margin-bottom: 0.125rem;

      input {
        &:checked {
          background-color: #212529 !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
        }
        &:disabled {
          pointer-events: none;
          filter: none;
          opacity: .5;
        }
        border-radius: 0.25em;

        float: left;
        margin-left: -1.5em;

        box-shadow: none !important;
        border-color: #212529 !important;
        color: white !important;
        font-size: 13px !important;

        width: 1em;
        height: 1em;
        margin-top: 0.25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 2px solid rgba(0,0,0,.25);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;

      }

      label {
        display: inline-block;
        font-weight: normal;

        &.pointer {
          cursor: pointer;
          color: #000;
          * {
            color: inherit;
          }
        }
        &.not-allowed {
          cursor: not-allowed;
          color: rgba(0,0,0,.5)!important;
          * {
            color: inherit;
          }
        }
      }
    }
  }

  .priority-service-error {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0!important;
    line-height: 1.25!important;
  }

  small {
    font-size: .875em !important;
  }
}