#messageSOSNotDropdown #iconHelpSOS {
    margin: 0 10px 0 -2px;
    font-size:22px;
    color:#0174DF;
    float:left;
}

#messageSOSNotDropdown p {
    color:#0174DF;
    margin-left:1px;
    font-size:12px;
    font-weight:700;
    display:inline;
}

#messageSOSDropdown {
    display: none;
}

#messageSOSDropdown div img {
    margin-top:15px;
    margin-bottom:15px;
}

#messageSOSDropdown div span {
    font-size: 24px;
    color: #0174DF;
    margin-top: 5px;
    float: right;
    margin-right: -10px;
}

#messageSOSDropdown #messageSOSDropdownp1 {
    font-weight: 700;
    font-size: 13px;
    color:black;
}

#messageSOSDropdown .messageSOSDropdownp {
    color:black;
    font-size:11px;
}

#messageSOSDropdown input#emailSOS {
    margin-bottom:15px;
}

.messageSOSUp {
    text-align: center;
    border-radius: 4px 4px 0 0;
    background-color: #A9F5F2;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    right: 10px;
    cursor: pointer;
    padding: 7px 15px;
}