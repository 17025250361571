@import "../shared/responsive-vars";

.home-advantages {
  margin-top: 15px;
  margin-bottom: 15px;

  #advTextContainer {
    position: relative;
  }
  #advImageContainer, #advTextContainer {
    padding: 0 15px;
  }
  .imgScrollXContainer{
    height: 320px;
    overflow: hidden;
    @media (max-width: $break-sm) {
      height: 250px;
    }
  }
  .imgScrollX{
    max-height: 320px;
    transition: all 0.5s ease-out;
    @media (max-width: $break-sm) {
      max-height: 250px;
    }
  }
  .textBox{
    min-height: 50px;
    max-height: 190px;
    width: 250px;
    @media (max-width: $break-sm) {
      width: 150px;
    }
    .adv {
      padding: 0 22px;
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
      color: #000;
      @media (max-width: $break-sm) {
        font-size: 0.79em;
      }
    }
  }

  .swiper-slide {
    width: max-content;
  }
  .related-nav {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    transition: all .3s ease;
    color: #000;
    opacity: 1;

    &:after {
      font-family: Glyphicons Halflings;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      font-size: 30px;
    }
    &:hover {
      color: #f7ae1c;
    }

    &.swiper-button-next:after {
      content: "\E258" !important;
    }
    &.swiper-button-prev:after {
      content: "\E257" !important;
    }

    &.swiper-button-prev {
      left: 0;
    }

    &.swiper-button-next {
      right: 0;
    }

    @media (max-width: $break-md) {
      &.swiper-button-prev {
        left: -40px;
      }

      &.swiper-button-next {
        right: -30px;
      }
    }

    @media (max-width: $break-sm) {
      height: 100%;
      width: 4%;
      top: 0;
      &.swiper-button-prev {
        left: 4px;
        top: 20px;
      }

      &.swiper-button-next {
        right: 8px;
        top: 20px;
      }
    }

    @media (max-width: 320px) {
      &.swiper-button-prev {
        left: 0;
      }

      &.swiper-button-next {
        right: 0;
      }
    }

    /* Mobile */
    @media (max-width: 320px) {
      width: 15%;
    }

  }

  .swiper-container {
    margin: 0;
    position: initial;

    @media (min-width: $break-md) {
      margin: 0 20px;
      position: initial;
    }

  }
}