#left-menu {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  overflow: hidden;
  width: 95%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: 100000;
  background-color: #FFF;
  background-clip: padding-box;
  outline: 0;
  transition: .2s ease;
  color: #000;

  @media (min-width: 992px) {
    width: 420px;
  }

  &.open {
    visibility: visible;
    left: 0;
  }

  * {
    color: inherit;
    font-size: inherit;
  }
  a {
    color: #000;
    text-decoration: none;
  }
  .icon {
    font-size: 20px;

    @media (min-width: 992px) {
      font-size: 24px;
    }
  }
  .icon-small {
    font-size: 12px;
  }
  .btn {
    text-transform: none;
    margin: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 13px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover, &:active {
      box-shadow: none;
    }
  }
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    list-style: none;
  }

  .lm-header {
    padding: 8px!important;
    border-bottom: 3px solid #dee2e6!important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tvi-logo-mobile {
      font-size: 52px;

      @media (min-width: 992px) {
        font-size: 60px;
      }
    }

    .lm-hr {
      align-items: center!important;
      justify-content: space-between!important;
      width: 100%!important;
      --bs-gutter-x: 0;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;

      .lm-hr-ca {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }

      .lm-hr-c {
        margin-left: 16px!important;
        border-width: 1px!important;
        border-left: 1px solid #6c757d!important;
        flex: 1 0 0%;
        width: 100%;
        max-width: 100%;

        #country-selector-btn {
          * {
            vertical-align: middle!important;
          }

          span {
            margin: 0 8px!important;
            font-size: 13px;

            @media (min-width: 992px) {
              font-size: 15px;
            }
          }

          .direction-icon {
            transition-duration: .2s;
          }
          .fa-rotate-180 {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            -webkit-filter: none;
            filter: none;
          }
        }
      }
    }
  }

  .lm-body {
    flex-grow: 1;
    padding: 16px 0;
    overflow-y: auto;
    font-size: 13px;

    @media (min-width: 992px) {
      font-size: 15px;
    }

    #country-selector {
      display: none;
      max-height: 35%;
      overflow: auto;
      padding: 0 16px;
      border-bottom: 3px solid #dee2e6!important;

      li {
        margin: 10px 0;

        .sw-alt {
          border-left: 1px solid #6c757d!important;
          margin-left: 16px;
          padding-left: 16px;
        }
      }
    }

    .hm-section {
      display: none;
      padding: 0 16px;
      &.active {
        display: block;
      }
      .hm-back {
        padding-left: 0;
        font-size: 13px;
        i {
          padding-right: 6px;
        }

        @media (min-width: 992px) {
          font-size: 15px;
        }
      }
      .hm-navigate {
        cursor: pointer;
      }

      .lm-br {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        justify-content: space-between!important;
        align-items: center!important;

        .lm-br-ca {
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
          padding: 0 12px;
          margin: 0;
        }
      }

      li {
        margin: 7px 0;

        @media (min-width: 992px) {
          margin: 10px 0;
        }
        &.i-1 {
          padding-left: 5px;
        }
        &.i-2 {
          padding-left: 10px;
        }
        &.i-3 {
          padding-left: 15px;
        }
        &.i-r {
          margin-top: 24px;
        }

        .lm-icon {
          font-size: 20px;
          color: #D6236F !important;
          margin-right: 16px;
          vertical-align: middle!important;

          @media (min-width: 992px) {
            .icon-big {
              font-size: 24px;
            }
          }
        }

        .lm-title {
          font-size: 16px;
          font-weight: bold;
          vertical-align: middle!important;

          @media (min-width: 992px) {
            .icon-big {
              font-size: 20px;
            }
          }
        }

        .pill-link {
          background-color: #e6e6e6 !important;
          border-radius: 50rem!important;
          padding: 4px 16px;
        }
      }

      &[data-type="main"] {
        li {
          margin: 15px 0!important;
        }
      }
      &[data-type="productType"] {
        li {
          margin: 10px 0!important;

          .pill-link {
            display: inline-block;
            margin-top: 16px;
          }
        }
      }
    }
  }
}