.widget-help {
  padding-top: 20px;
  display: flex;
  justify-content: center;

  label {
    display: flex;
    border-radius: 50rem !important;
    border: 1px solid #dee2e6!important;
    padding: 10px;
    box-shadow: 0 0 0.5rem 0.3rem rgba(0, 0, 0, 0.15) !important;
    cursor: pointer;
  }

  .principal {
    width: 80px;
    border-radius: 50% !important;
  }

  .secondary {
    position: absolute;
    width: 35px;
    left: 69%;
    top: -10%;
  }

  .text {
    padding-left: 2rem !important;
    align-content: center;
  }

  span {
    color: #000;
    &.title-text {
      display: block;
      font-weight: 700;
    }
  }
}