@media (max-width: 991px) {
    /*MOBILE - TABLET*/
    #i18n-container {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        padding: 10px;
        height: 215px;
        border: 1px solid;
        box-shadow: 0 0 10px #000;
        -webkit-box-shadow: 0 0 10px #000;
    }
    #i18n-content {
        margin-top: 20px;
        text-align: left;
        color: black;
        width: 100%;
    }
    #i18n-content * {
        color: inherit;
        font-size: 14px;
    }
    #i18n-content h2 {
        font-size: 15px !important;
    }
    #i18n-content .footer a {
        font-size: 11px !important;
    }
    #i18n-content p {
        display: none;
    }
    #i18n-content #title {
        font-size: 30px !important;
    }
}
@media (min-width: 992px) {
    #i18n-container {
        position: fixed;
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 290px;
        width: 30%;
        padding: 10px;
        z-index: 100;
        background-color: white;
        color: black;
        border: 1px solid;
        box-shadow: 0 0 10px #000;
        -webkit-box-shadow: 0 0 10px #000;
    }
    #i18n-content {
        text-align: left;
        color: black;
        width: 100%;
        margin: 0 auto;
        padding: 16px 30px;
    }
    #i18n-content * {
        color: inherit;
        font-size: 13px;
    }
    #i18n-content .footer a {
        font-size: 9px;
    }
    #i18n-content #title {
        font-size: 35px;
    }
}

@media (min-width: 1200px) {
    #i18n-container {
        position: fixed;
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 290px;
        width: 26%;
        padding: 10px;
        z-index: 100;
        background-color: white;
        color: black;
        border: 1px solid;
        box-shadow: 0 0 10px #000;
        -webkit-box-shadow: 0 0 10px #000;
    }
}

#i18n-content h2 {
    margin-top: 0;
    text-transform: none;
    font-size: 17px;
}
#i18n-content #i18n-close {
    color: #fff;
    background-color: #000;
    width: 23px;
    height: 23px;
    font-weight: 700;
    font-size: 15px;
    border-radius: 15px;
    display: block;
    position: absolute;
    top: 13px;
    right: 20px;
    cursor: pointer;
    text-align: center;
}
#i18n-content .i18n-option-box, #i18n-content .i18n-current-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:7px;
    margin:16px auto;
    text-align: center;
    border:1px solid;
    cursor:pointer;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#i18n-content .i18n-option-box:not(.option-continue) {
    background-color: #03a9f4;
    color: white;
}

#i18n-content .i18n-option-box > *, #i18n-content .i18n-current-box > * {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
    margin-left: 10px;
}
#i18n-content .i18n-option-box > img, #i18n-content .i18n-current-box > img {
    float: left;
}

#i18n-content .footer {
    text-align: center;
}