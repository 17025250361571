.loyalty-bar{
    text-align: center;
    width: 100%;
    height: 35px;
}
.loyalty-container {
    position: relative;
}
.loyalty-container .loyalty-item {
    display: none;
}
.loyalty-container .loyalty-item:first-child {
    display: block;
}