@import "../shared/responsive-vars";

.modal {
  z-index: 9999999991;
}
.slide-up-container {
  background: #fff;
  height: 84%;
  overflow-y: auto;
}
.slide-up-slider {
  overflow-y: hidden;
  position: fixed;
  background-color: #fff;
  height: 92%;
  width: 700px;
  max-width: 700px;
  left: 33%;
  bottom: 30px;
  z-index: 999999999;
  -webkit-transition:-webkit-transform .6s ease;
  -moz-transition:   -moz-transform .6s ease;
  -ms-transition:    -ms-transform .6s ease;
  transition:        transform .6s ease;

  /* completely offscreen */
  -webkit-transform: translate(0, 124%);
  -moz-transform: translate(0, 124%);
  -ms-transform: translate(0, 124%);
  transform: translate(0, 124%);

  /*border: 1px solid #fff;*/
  border-radius: 15px;
  box-sizing: border-box;

  &.open {
    /* visible */
    -webkit-transform: translate(0, 0%);
    -moz-transform: translate(0, 0%);
    -ms-transform: translate(0, 0%);
    transform: translate(0, 0%);
  }
  .slide-up-header {
    display: block;

    .row {
      border-bottom: 1px solid #b2b2b2;
      position: relative;
      padding: 16px 15px;

      .btn-icon {
        color: #000;
        font-size: 25px;
      }

      .btn-text {
        font-weight: bold;
        color: #000;
        font-size: 15px;
        vertical-align: text-bottom;
      }

      .close-filters {
        position: absolute;
        top: 11px;
        right: 13px;
        cursor: pointer;
        background: #fff;
        border-radius: 50%;
        border: 1px solid #555;
        width: 30px;
        height: 30px;

        span {
          color: #000;
          font-size: 21px;
          display: inline-block;
          padding: 3px 3px;
        }
      }
    }
  }

  .slide-up-footer {
    display: block;
  }

  .slider-section {
    &:after {
      content: " ";
      border-bottom: 1px solid #b2b2b2;
      margin: 5px -15px 5px;
      display: block;
    }

    $breakpoint-mobile: 412px;

    .slider-section-title {
      margin: 0;
      /*To switch between mobile and computer*/
      @media (max-width: $breakpoint-mobile) { /*Mobile*/
        padding: 10px 0 10px 0;
        line-height: 2;
      }
      @media (min-width: $breakpoint-mobile+1) {/*Computer*/
        padding: 10px 0;
      }
      font-weight: bold;
      color: #000;
      cursor: pointer;

      &:before {
        content: "\E258";
        position: absolute;
        right: 25px;
        @media (max-width: $breakpoint-mobile) { /*Mobile*/
          top: 18px;
        }
        @media (min-width: $breakpoint-mobile+1) {/*Computer*/
          top: 13px;
        }
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.expanded:before {
        content: "\E259";
      }
    }
    .slider-section-content {
      display: none;
      position: relative;
      &.alwaysShown {
        display: block;
      }
    }
  }

  /* Mobile: increase font-size */
  /*.list-filter-parents {
    display: none;
    font-size: 16px;
    .list-filter-unfold {
      font-size: 21px;
    }
  }
  .list-filter {
    .list-filter-link > .filter-checkbox {
      font-size: 21px;
    }
    ul li {
      padding: 9px 0;
    }
  }*/
}

@media (max-width: $break-lg) {
  .slide-up-slider {
    height: 85%;
    width: 50%;
    max-width: 50%;
    left: 24%;
    bottom: 63px;
    border-radius: 15px;
  }
}

@media (min-width: $break-lg) {
  .slide-up-slider {
    height: 85%;
    width: 33%;
    max-width: 33%;
    left: 33%;
    bottom: 63px;
    border-radius: 15px;
  }
}

@media (max-width: $break-md) {
  .slide-up-slider {
    height: 85%;
    width: 80%;
    max-width: 80%;
    left: 11%;
    bottom: 0;
    border-radius: 15px 15px 0 0;
  }
}

@media (max-width: $break-xs) {
  .slide-up-slider {
    height: 88%;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }
}