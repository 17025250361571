.shopping-cart-title {
    height: 40px;
    position: absolute;
    padding: 0 10px;
    top: 0;
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.shopping-cart-title * {
    color: #000;
}
.shopping-cart-title>div {
    line-height: 40px;
    width: 50%;
    float: left;
}
.shopping-cart-title * {
    font-size: 15px;
}
.sct-title .glyphicon {
    font-size: 20px;
    vertical-align: text-bottom;
    color: #b7b7b7;
}
.sct-count {
    text-align: right;
}
.shopping-cart-body {
    margin-top: 50px;
    padding: 0 10px;
}
.scb-empty {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
}
.scb-empty .emptycart-icon {
    font-size: 100px;
    color: #D2D2D2;
    margin-top: 10px;
}
.scb-empty-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 167px;
    z-index: 1;
}
.scb-empty-wrapper .scb-empty {
    top: unset;
    bottom: 0;
    left: 0;
}
.shopping-cart-items-list {
    overflow: auto;
    height: 0;
    width: 100%;
}
.sc-item {
    clear: both;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    height: 111px;
}
.sc-item-child {
    padding-left: 20px;
}
.sci-data {
    height: 88px;
    position: relative;
}
.sci-data>span {
    font-size: 11px;
    color: #000;
}
.sci-name {
    font-weight: bold;
}
@media (min-width: 992px) {
    /*DESKTOP*/
    .sci-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 63%;
        display: inline-block;
    }
    .sci-options {
        margin-top: -5px;
    }
}
@media (max-width: 767px) {
    /*MOBILE*/
    .sci-name {
        overflow: hidden;
        width: 50%;
        display: inline-block;
    }
    .sci-options {
        margin-top: -5px;
    }
}
@media (max-width: 320px) {
    /*MOBILE*/
    .sci-name.sci-name-cart {
        overflow: hidden;
        width: 25%;
        display: inline-block;
    }
    .sci-options {
        margin-top: -5px;
    }
}
.sci-option {
    font-size: 11px;
    display: block;
    line-height: 11px;
    margin: 3px 0;
    text-align: left;
}
.sci-colorPreview {
    display: inline-block;
    width: 15px;
    height: 11px;
    float: left;
    margin-right: 5px;
    border: 1px solid #000;
}
.sci-options .product-sprite {
    transform: scale(.5);
    display: inline-block;
    float: left;
    margin: -6px -3px 0 -6px;
}
.sci-price {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px !important;
}
.sci-price-value, #scs-price-value {
    font-size: 12px !important;
    color: #000;
}
.sci-quantity-container {
    position: absolute;
    bottom: 0;
    left: 100px;
    background: #fff;
}
.input-group.sci-quantity-container>button {
    width: 25px;
    height: 27px;
    margin-top: -4px;
    padding: 0;
}
.input-group.sci-quantity-container>button>span {
    color: #000;
}
.input-group.sci-quantity-container>button:disabled>span {
    color: #ddd;
    cursor: not-allowed;
}
.input-group.sci-quantity-container>input {
    width: 45px;
    text-align: center;
}
.sci-image {
    float: left;
    margin-right: 10px;
    width: 90px;
    line-height: 80px;
    padding: 2px;
    text-align: center;
    border: 1px solid #ddd;
}
.sci-image img {
    max-height: 84px;
    max-width: 84px;
    vertical-align: middle;
}
.sc-spatula .sci-image img {
    padding: 10px;
}
.sci-remove, .scs-remove, .sci-modify {
    float: right;
    text-align: center;
    cursor: pointer;
}
.sci-remove .glyphicon, .scs-remove .glyphicon, .sci-modify .glyphicon {
    line-height: 20px;
    padding: 0 3px;
}
.sci-remove {
    position: absolute;
    top: 0;
    right: 0;
}
.sci-modify {
    position: absolute;
    top: 0;
    right: 20px;
}
.shopping-cart-summary {
    position: absolute;
    width: 100%;
    bottom: 35px;
    left: 0;
    padding: 0 10px;
    border-top: 1px solid #ddd;
}
.scs-shipping {
    background-color: #c5c5c5;
    margin: 0 -10px;
    padding: 10px;
}
.scs-shipping * {
    color: #000;
    font-size: 15px;
}
.scs-shipping .glyphicon {
    font-size: 20px;
    margin-right: 10px;
    vertical-align: middle;
}
#free-shipping-left {
    font-weight: bold;
}
.scs-total-container {
    padding: 10px 0;
}
.scs-total-container * {
    color: #000;
}
#scs-total {
    float: right;
}
#scs-total, #scs-total * {
    font-weight: bold;
}
.validate-cart {
    padding: 15px 30px;
}

#payWithAmazon {
    text-align: center;
}
#payWithAmazon.newcart {
    margin: auto;
}

.amazon-pay-button {
    position: relative;
}

    .amazon-pay-button-box {
        text-align: center;
        padding: 10px 0;
    }
    .paypal-button-box {
        text-align: center;
        padding: 10px 0;
    }
    /*.paypal-button-box #paypal-button-container {
        display: inline-block !important;
        width: 200px;
    }*/

.shopping-cart-body .related-container {
    margin-left: 12px;
    margin-right: 12px;
}
