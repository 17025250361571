.cart-body {
  margin-top: 10px;
  padding: 0 20px;

  .scs-footer {
    background-color: #fff;
    &.emptyCart {
      display: none;
    }
    @media (min-width: 1200px) {
      position: absolute;
      width: 100%;
      /*bottom: 5px;*/
      bottom: 0;
      left: 0;
      &.emptyCart {
        padding: 0 20px 0 10px;
        display: none;
      }
    }
    .external-payment-methods {
      margin: 20px 0;
    }
    .other-payment-methods {
      text-align: center;
      margin-bottom: 15px;
      a {
        color: #000;
        text-decoration: underline;
      }
      @media (max-width: 768px) {
        //display: none;
      }
    }

    #response-voucher {
      margin-top: 10px;
    }

    .amazon-pay-button-box {
      padding-left: 18px;
    }
  }

  #cb-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 1;
    opacity: .8;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      font-size: 100px;
      display: block;
      width: 100%;
      text-align: center;

      &.cb-spin {
        -webkit-animation: pf-spin 2s infinite linear;
        animation: pf-spin 2s infinite linear;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }
    }
  }

  .scs-shipping {
    background-color: #c5c5c5;
    margin: initial;
    padding: 10px;
    text-align: center;
    * {
      color: #000;
      font-size: 15px;
    }
  }

  .scs-total-container {
    padding: 10px 0;
    * {
      color: #000;
    }
    .scs-total {
      float: right;
      &.b, &.b * {
        font-weight: bold;
      }
      @media (min-width: 992px) {
        padding-right: 20px;
      }
    }

    &.bg-grey {
      background-color: #c5c5c5;
      margin: 0 -20px;
      padding: 10px 20px;
      &.sub-total {
        margin-top: 10px;
      }
    }

    /* remove when old cart removed */
    #scs-total, #scs-total * {
      font-weight: normal;
    }
    .scs-totalb {
      float: right;
      & * {
        font-weight: bold !important;
      }
      @media (min-width: 992px) {
        padding-right: 20px;
      }
    }
    /* END remove when old cart removed */
  }

  .scs-checkout-btn {
    margin-top: 10px;

    .validate-cart {
      padding: 15px 30px;
      font-weight: bold;
    }

    .external-payment-methods {
      @media (max-width: 768px) {
        //display: none;
      }
      @media (max-width: 425px) {
        //display: none;
        margin: 5px 0;
        .amazon-pay-button-box {
          padding-left: 0;
        }
      }
    }
  }

  .scs-extras {
    clear: both;
    min-height: 40px;
    margin-top: 10px;
    cursor: pointer;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    -ms-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;

    &:last-child {
      margin-bottom: 20px;
    }

    &.scs-complements {
      border-bottom: 1px solid #ddd;
    }

    &.scs-payment-methods {
      border-bottom: 1px solid #ddd;
    }

    .scs-extras-title {
      font-weight: bold;
      position: relative;

      &:before {
        content: "\E114";
        position: absolute;
        right: 5px;
        top: 4px;
        display: inline-block;
        font-family: Glyphicons Halflings;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }
    }

    .scs-extras-body {
      display: none;
      margin: 10px -10px 0;
      .image-box {
        border: 1px solid #ccc;
      }

      .complement-add-to-cart {
        border: 1px solid;
        text-transform: none;
        color: #000 !important;
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 1;
        * {
          color: #000;
        }
        .glyphicon {
          color: #000;
          font-size: 15px;
        }
      }

      .validate-voucher {
        margin: 0;
      }
    }

    &.open {
      .scs-extras-body {
        display: block;
      }
      .scs-extras-title:before {
        content: "\E113";
      }
    }
  }

  .scs-items {
    .sc-item {
      clear: both;
      border-bottom: 1px solid #ddd;
      padding: 10px 0;
      height: 111px;
    }
    .sc-item-child {
      padding-left: 20px;
    }
    .sci-data {
      height: 88px;
      position: relative;

      & > span {
        font-size: 11px;
        color: #000;
      }
    }
    .sci-name {
      font-weight: bold;
    }
    @media (min-width: 992px) {
      max-height: 100%;
      overflow: auto;
      padding-right: 20px;
      /*DESKTOP*/
      .sci-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 63%;
        display: inline-block;
      }
      .sci-options {
        margin-top: -5px;
      }
    }
    @media (max-width: 767px) {
      /*MOBILE*/
      .sci-name {
        overflow: hidden;
        width: 50%;
        display: inline-block;
      }
      .sci-options {
        margin-top: -5px;
      }
    }
    @media (max-width: 320px) {
      /*MOBILE*/
      .sci-name.sci-name-cart {
        overflow: hidden;
        width: 25%;
        display: inline-block;
      }
      .sci-options {
        margin-top: -5px;
      }
    }
    .sci-option {
      font-size: 11px;
      display: block;
      line-height: 11px;
      margin: 3px 0;
      text-align: left;
    }
    .sci-colorPreview {
      display: inline-block;
      width: 15px;
      height: 11px;
      float: left;
      margin-right: 5px;
      border: 1px solid #000;
    }
    .sci-price {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px !important;
    }
    .sci-price-value, #scs-price-value {
      font-size: 12px !important;
      color: #000;
    }
    .sci-quantity-container {
      position: absolute;
      bottom: 0;
      left: 100px;
      background: #fff;
    }
    .input-group.sci-quantity-container>button {
      width: 25px;
      height: 27px;
      margin-top: -4px;
      padding: 0;
    }
    .input-group.sci-quantity-container>button>span {
      color: #000;
    }
    .input-group.sci-quantity-container>button:disabled>span {
      color: #ddd;
      cursor: not-allowed;
    }
    .input-group.sci-quantity-container>input {
      width: 45px;
      text-align: center;
    }
    .sci-image {
      float: left;
      margin-right: 10px;
      width: 90px;
      line-height: 80px;
      padding: 2px;
      text-align: center;
      border: 1px solid #ddd;
    }
    .sci-image img {
      max-height: 84px;
      max-width: 84px;
      vertical-align: middle;
    }
    .sc-spatula .sci-image img {
      padding: 10px;
    }
    .sci-remove, .scs-remove, .sci-modify {
      float: right;
      text-align: center;
      cursor: pointer;
    }
    .sci-remove .glyphicon, .scs-remove .glyphicon, .sci-modify .glyphicon {
      line-height: 20px;
      padding: 0 3px;
    }
    .sci-remove {
      position: absolute;
      top: 0;
      right: 0;
    }
    .sci-modify {
      position: absolute;
      top: 0;
      right: 20px;
    }
  }
  /*.payWithAmazonChooseOption {
    display: none;
  }*/

  .payWithAmazonChooseOption {
    display: block;
    text-align: center;
    color: #000;
    margin: 6px 0;
    padding: 0;
    font-size: 0.9em;
  }

  .related-container {
    position: relative;
  }

  .payment-methods-explanation {
    font-size: 11px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    cursor: default;
  }

  .scs-extras.scs-payment-methods {
    .scs-extras-title {
      color: #000;
      text-align: center;
      font-weight: normal;
      &:before {
        color: #696969;
      }
    }
  }

  #payment-method-icons {
    clear: both;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: default;

    @media (max-width: 768px) {
      .payment-method {
        margin: 7px 0 0 5px;
        max-height: 36px;
      }
    }
    @media (min-width: 769px) {
      .payment-method {
        max-height: 36px;
        margin: 2px 0 0 2px;
      }
    }
  }
}

@keyframes pf-spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.new-cart {
  .mobile-menu-hamburger {
    display: none !important;
  }
  
  .footer {
    width: 100%;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
    background-color: white;

    @media (min-width: 1024px) {
      width: 520px;
    }

    .scs-shipping {
      margin: 2px 0 0;
      background-color: #707070;
      justify-content: center;
      display: flex;
      align-items: center;

      .mdi-maps-local-shipping {
        padding: 0 5px;
      }

      * {
        color: white;
        font-weight: bolder;
      }
    }

    .submit {
      padding: 10px 15px;

      .validate-cart {
        background-color: #4daf50;
        color: white;
        font-weight: bold;
        border-radius: 5px;
      }
    }
  }

  .menu-right-full-title {
    text-align: left !important;
  }

  .body {
    margin-top: 50px;
    padding: 15px;
    padding-right: 30px;
    margin-bottom: 100px;
  }

  &__total-price {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    line-height: 1.2;
    padding: 16px 0;

    strong, #scs-total-value {
      font-size: 20px;
      color: black;
      font-weight: bold;
    }

    &--secondary {
      display: block;
      font-size: 13px;
      font-weight: lighter;
      opacity: .7;
    }
  }

  &__discount {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 16px 0 0;
    line-height: 1.2;

    #scs-discount-value, strong {
      font-size: 14px;
      font-weight: bold;
      color: black;
    }
  }

  &__section {
    margin: 36px 0;

    &-title {
      font-weight: bolder;
    }
  }

  &__payment-methods {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__external-payments {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    margin: 16px;
  }

  &__shipping {
    width: 100%;
    margin: 2px 0 0;
    padding: 10px 16px;
    background-color: #707070;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bolder;

    i {
      margin: 0 5px;
    }
  }

  ul,
  li {
    text-indent: 0;
    list-style-type: none;
  }

  .new-cart__external-payments {
    margin-top: 0;

    .external-payment {
      width: 45%;
    }
  }

  .buttons {
    display: flex;
  }

  .item-cart__quantity {
    position: relative;

    .number {
      color: #52A4B9;
      font-weight: bold;
    }

    .quantity {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid black;
      width: 70px !important;
      border-radius: 20px;
      padding: 5px 10px;

      .icon {
        font-size: 15px;
        color: black;
      }

      &.closed {
        .icon::before {
          content: "\e114";
        }
      }

      &.opened {
        .icon::before {
          content: "\e113";
        }
      }

      &.locked {
        background-color: #dee2e6;

        .icon {
          display: none;
        }
      }
    }

    .quantity-dropdown {
      position: absolute;
      margin-top: 5px;
      background-color: white;
      width: 150%;
      border: 2px solid #dee2e6;
      max-height: 570%;
      overflow-y: auto;
      border-radius: 5px;
      z-index: 1;

      .item-quantity {
        padding: 5px 10px;
        cursor: pointer;
        &:hover, &.selected {
          background-color: #dee2e6;
        }
      }

      &.closed {
        display: none;
      }

      &.opened {
        display: block;
      }
    }
  }

  .list-cart-childs {
    padding: 0;

    .list-cart-childs__item {
      padding-left: 2rem;
      padding-top: 10px;
    }
  }

  .option {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1px 0;

    span {
      font-size: 11px;
      display: block;
      color: #696969;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      font-size: 12px;
      margin-right: 5px;
    }

    .color-preview {
      border: 1px solid black;
      margin-right: 5px;
    }
  }

  .shipping-notice-cost {
    padding: 10px;
    border-radius: 0.25rem;
    background-color: #E5E5E5;
    margin-bottom: 10px;
    width: 100%;
    font-size: 12px;
    color: black;
  }

  .list-cart {
    margin: 0;
    padding: 0;
    border-bottom: 3px solid #dee2e6;

    &__item {
      display: block;
      padding: 16px 0;
      article .item-cart__quantity {
        padding-left: 1.3rem;
      }
    }

    &__item:first-child {
      padding: 0 0 16px;
    }

    &__item:not(last-child) {
      border-bottom: 1px solid #dee2e6;
    }

    &-childs {
      margin-top: 8px;
      &__item {
        border-top: 1px solid #ccc;
        article .item-cart__quantity {
          padding-left: 0;
        }
      }
    }
  }

  .item-cart {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    &__content {
      width: 100%;

      &--primary {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: bolder;
      }

      &--secondary {
        width: 100%;
        min-height: 32px;
        margin-bottom: 5px;

        & .color-preview {
          width: 15px;
          height: 15px;
        }
      }

      &--actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
      }
    }

    &__image {
      width: 30%;
      margin-right: 16px;
      aspect-ratio: 1 / 1;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: bolder;
      color: black;
      font-size: 14px;
    }

    &__price {
      font-size: 14px;
      font-weight: bolder;
      color: black;
    }

    &__quantity {
      &--input {
        max-width: 50px;
      }
    }
  }

  .link-icon__primary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: none;
    padding: 0;

    i {
      color: black;
      font-size: 18px;
    }

    &:hover {
      background-color: black;

      i {
        color: white;
      }
    }
  }

  .scs-extras {
    .scs-extras-body {
      padding: 0;
    }

    .list-product {
      .title {
        color: black;
        font-weight: bold;
        text-align: left !important;
      }

      .price {
        text-align: left !important;

        .current span {
          font-size: 16px !important;
        }
      }
    }

    .complement-add-to-cart {
      .glyphicon {
        display: none;
      }

      border-radius: 4px;
      text-decoration: none;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
      width: 100%;
      background-color: white;
      border: 1px solid #212529;

      &:hover {
        background-color: #212529;
        .listingAddCartLabel {
          color: white;
        }
      }

      .listingAddCartLabel {
        color: black;
        font-weight: bolder;
        font-size: 14px;
      }
    }
  }

  .new-cart__section-title {
    color: black;
    font-size: 16px;
  }

  .klarna-banner {
    margin-top: 15px;
  }

  #payment-method-icons {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    img {
      max-height: 40px;
      margin: 5px;
    }
  }

  .btn__secondary {
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 100%;
    background-color: white;
    border: 1px solid #212529;
    font-weight: bold;
    color: black;

    &:hover {
      background-color: #212529;
      color: white;
    }
  }

  #voucher-code {
    height: 40px !important;
  }

  #response-voucher {
    margin-top: 20px;
  }

  .related-container {
    position: relative;
  }

  .sci-remove, .sci-modify {
    position: initial;
  }

  .sc-item {
    border-bottom: none;
    padding: 0;
    height: auto;
  }

  #cb-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 1;
    opacity: .8;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      font-size: 100px;
      display: block;
      width: 100%;
      text-align: center;

      &.cb-spin {
        -webkit-animation: pf-spin 2s infinite linear;
        animation: pf-spin 2s infinite linear;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }
    }
  }
}

