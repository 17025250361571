@import "../shared/responsive-vars";

.menu-right-full-container, .menu-left-full-container {
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  position: fixed;
  top: 0;
  right: -800px;
  z-index: 99999999;
  background-color: #FFF;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -ms-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;

  &.open {
    visibility: visible;
    right: 0;
  }

  .menu-header {
    border-bottom: 1px solid #ddd;
    position: fixed;
    z-index: 999;
    width: 100%;
    background: #fff;
    .glyphicon {
      font-size: 25px;
      line-height: 40px;
    }

    .menu-right-full-title {
      text-align: center;
      height: 50px;
      line-height: 4;
      .title {
        font-weight: bold;
        color: #000;
      }
    }

    .mobile-menu-hamburger, .menu-full-close {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #FFF;
      height: 40px;
      width: 40px;
      z-index: 950;
      text-align: center;
      cursor: pointer;
      margin: 5px 10px;
    }

    .mobile-menu-hamburger {
      left: 0;
    }

    .menu-full-close {
      right: 0;
    }
  }

  .body {
    margin-top: 60px;
    width: 100%;
  }

  width: 100%;
  @media (min-width: 1024px) {
    /*DESKTOP*/
    width: 520px;
    .menu-header, .body {
      width: 520px;
    }
    .menu-full-close {
      position: absolute;
      top: 0;
      background-color: #FFF;
      height: 40px;
      width: 40px;
      z-index: 950;
      text-align: left;
      cursor: pointer;
      margin: 5px 10px;
    }

    .mobile-menu-hamburger {
      display: none;
    }
  }
}

.menu-left-full-container {
  right: unset;
  left: -800px;
  overflow: hidden;

  #userDropDown {
    z-index: 1002;
  }

  &.open {
    left: 0;
  }

  .menu-header {
    .menu-left-full-title {
      text-align: center;
      height: 50px;
      line-height: 4;
      .title {
        font-weight: bold;
        color: #000;
      }
    }

    .mobile-menu-hamburger {
      right: 0;
    }

    .menu-full-close {
      left: 0;
    }
  }
  @media (min-width: 1024px) {
    /*DESKTOP*/
    width: 600px;
    .menu-header, .body {
      width: 600px;
    }
  }
}