@import "../shared/responsive-vars";
@import "product-icons";

/* Selected value */
.cart-value-selected {
  float: right;
  margin: -6px 50px 0 0;
  padding: 0;
  line-height: 2.4;
  width: 150px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $break-xs) {
    width: 80px;
  }

  .sticker-color-select, .material-color-select, .text-color-select {
    width: 25px;
    height: 25px;
    margin: -6px 0;

    span {
      font-size: 18px;
      margin: 0px 6px;
    }
  }

}

.product-popup-options #add-to-cart-model-gender {
  margin-top: 10px;
}

/* Color Items */
.sticker-color-select, .material-color-select,  .text-color-select {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid;

  span {
    color: rgba(255, 255, 255, 0.995);
    font-weight: 900;
    font-size: 22px;
    float: left;
    margin: 0 7px;
    display: block;
    line-height: 1.2;
    text-shadow: 1px 1px #888;
  }

  &:after {
    content: 'â';
    position: absolute;
    top: -4px;
    left: 4px;
    font-size: 22px;
    color: #000;
    opacity: 0;
    transition: all .2s;
    transform: scale(0);
  }

  &.dark-color {
    &:after {
      color: #fff;
    }
  }

  &.color-selected, &.material-color-selected, &.text-color-selected {
    border: 3px solid #000;

    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}
#preview-color, #preview-material-color {
  border: 1px solid #000;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  top: 10px;
  display: none;
}

.add-to-cart-component {
  border-bottom: 1px solid #b2b2b2;
}

.sticker-more-options:not(:last-of-type) {
  border-bottom: 1px solid #b2b2b2;
}

.pers-size-block {
  float: left;
  width: 47%;
}

.pers-size-separator {
  width: 4%;
  line-height: 32px;
  display: block;
  float: left;
  text-align: center;
  margin: 0 3px 0 3px;
}

.customizable-box {
  margin: 0 0 15px 0;
}

#upper-size-alert, #product-oversize-notice, #multiple-of-five-alert {
  color: #5278ce;
}
#product-oversize-notice {
  display: none;
  margin: 0 10px;
  padding: 10px;
}
.wrong-size-alert {
  color: #f44336;
}
.wrong-size-alert, #upper-size-alert, #multiple-of-five-alert {
  display: none;
  margin: 5px 0 20px;
}

.image-option-radio input[type=radio]:not(:checked) + label .radio-off,
.product-option-radio input[type=radio]:not(:checked) + label .radio-off,
.sticker-sizes-cr input[type=radio]:not(:checked) + label .cr-radio-off,
.sticker-options-plain-cr input[type=checkbox]:not(:checked) + label .cr-check-off,
.sticker-options-cr input[type=checkbox]:not(:checked) + label .cr-check-off,
.sticker-options-cr input[type=radio]:not(:checked) + label .cr-radio-off {
  opacity: 1;
  transform: scale(1);
}

.image-option-radio input[type=radio]:checked + label .radio-on,
.product-option-radio input[type=radio]:checked + label .radio-on,
.sticker-sizes-cr input[type=radio]:checked + label .cr-radio-on,
.sticker-options-plain-cr input[type=checkbox]:checked + label .cr-check-on,
.sticker-options-cr input[type=checkbox]:checked + label .cr-check-on,
.sticker-options-cr input[type=radio]:checked + label .cr-radio-on {
  opacity: 1;
  transform: scale(1);
}

.image-option-radio input[type=radio] + label .radio-option,
.product-option-radio input[type=radio] + label .radio-option,
.sticker-sizes-cr input[type=radio] + label .cr-radio,
.sticker-options-plain-cr input[type=checkbox] + label .cr-check,
.sticker-options-cr input[type=checkbox] + label .cr-check,
.sticker-options-cr input[type=radio] + label .cr-radio {
  font-size: 20px;
  color: #000;
  background-color: #FFF;
  border-radius: 12px;
  vertical-align: sub;
  opacity: 0;
  transition: all .1s;
  transform: scale(0);
  position: absolute;
  left: 8px;
}

.sticker-options-cr input[type=radio]:checked + label,
.sticker-options-cr input[type=checkbox]:checked + label {
  font-weight: bold;
}

.sticker-options-cr input[type=radio]:checked + label.plain,
.sticker-options-cr input[type=checkbox]:checked + label.plain {
  font-weight: normal;
}

.image-option-radio input[type=radio],
.product-option-radio input[type=radio],
.sticker-sizes-cr input[type=radio] {
  display: none;
}

.image-option-radio label *,
.product-option-radio label *,
.sticker-sizes-cr label * {
  color: inherit;
}

.form-control-wrapper .floating-label {
  font-size: 11px;
  top: 8px;
  @media (max-width: $break-sm) {
    font-size: 9px;
    top: 8px;
  }
}

.image-option-radio input[type=radio] + label,
.product-option-radio input[type=radio] + label,
.sticker-sizes-cr input[type=radio] + label {

  p ,
  .pers-size-calculated,
  .pers-size-calculated #size-height-pers,
  .pers-size-price,
  span.size-prices span.size-price,
  span.rate-prices span.item-rate-price,
  .pers-size-price #persSizePrice {
    font-size: 12px;
  }
}

.image-option-radio input[type=radio]:checked + label,
.product-option-radio input[type=radio]:checked + label,
.sticker-sizes-cr input[type=radio]:checked + label {
  color: #000;

  p ,
  .pers-size-calculated,
  .pers-size-calculated #size-height-pers,
  .pers-size-price,
  #persSizePrice,
  span.size-prices span.size-price,
  span.rate-prices span.item-rate-price {
    font-weight: bold;
  }
}

.image-option-radio input[type=radio] + label,
.product-option-radio input[type=radio] + label,
.sticker-sizes-cr input[type=radio] + label {
  cursor: pointer;
  position: relative;
  width: 100%;
  vertical-align: text-top;
  padding: 8px 8px 8px 35px;
  transition: all .2s;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}

.product-popup-options {
  .product-option-radio:not(:last-of-type) input[type=radio] + label {
    border-bottom: 1px solid #696969;
  }
  .product-option-radio.blind-option input[type=radio] + label {
    border-bottom: none;
  }
}

.sticker-options-cr input[type=checkbox], .sticker-options-cr input[type=radio],
.sticker-options-plain-cr input[type=checkbox] {
  display: none;
}
.sticker-options-cr input[type=checkbox]+label,
.sticker-options-cr input[type=radio]+label,
.sticker-options-plain-cr input[type=checkbox]+label {
  cursor: pointer;
  position: relative;
  width: 100%;
  vertical-align: text-top;
  padding: 8px 8px 0 35px;
  transition: all .2s;
  font-size: 12px;
  line-height: 1.9;
  font-weight: normal;
  color: #000;
}


.sticker-size-pers, .product-custom-pack {
  background-color: rgba(217,217,217,0.6);
}

.product-custom-pack {
  input[name=customPack], input[name=add-to-cart-customPack] {
    width: 100px;
  }
}

/*@media (min-width: 768px) {
  .rate-prices {
    left: 40%;
  }
}*/
.size-prices,
.rate-prices {
  position: absolute;
  right: 5%;
  color: #009587 !important;

  .item-offers-oldPrice {
    font-size: 13px;
    text-decoration: line-through;
    padding: 0 0 0 10px;
  }
}

.pers-size-calculated {
  float: left;
  line-height: 32px;
  color: inherit;
  margin-left: 10px;
}

@media (max-width: 991px) {
  .pers-size-calculated {
    width: 55px;
  }
}

@media (min-width: 992px) {
  .pers-size-calculated {
    width: 65px;
  }
}

.pers-size-price {
  display: none;
  position: absolute;
  right: 5%;
  color: #009587 !important;
  line-height: 32px;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .pers-size-price {
    position: absolute;
    right: 5%;
  }
}

#personalizedImageUpload, #personalizedText {
  margin-top: 10px;
}
#personalizedImageUpload * {
  cursor: pointer;
}
#personalizedImageUpload #personalizedImage, .personalizedImageMultiProduct {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.btn-add-to-cart {
  padding-left: 0;
  padding-right: 0;
  font-weight: bold;
  color: #FFF!important;
  &.no-upper {
    text-transform: none !important;
  }
}
.btn-item-price {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.btn-item-cart {
  position: absolute;
  color: inherit;
  left: 10px;
  font-size: 25px;
}
.quantity-item-cart {
  padding: 8px 0;
  margin: 5px 0 0;

}
.quantity-item-cart > * {
  display: table-cell;
}
.quantity-item-cart .qty-btn {
  width: 25%;
  padding: 0;
}
.quantity-item-cart .qty-btn span {
  color: #000;
}
.quantity-item-cart .qty-btn:disabled span {
  color: #ddd;
}
.qty-display {
  width: 40%;
  text-align: center;
  padding: 0 !important;
}
.atc-loyalty {
  text-align: right;
  color: #000;
}
.atc-loyalty * {
  color: inherit;
}
.atc-loyalty .atc-loyalty-icon {
  font-size: 25px;
  vertical-align: middle;
}

.mobile-fixed-add-to-cart-component {
  @media (max-width: $break-sm) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 800;
    background-color: #FFF;
    width: 100%;
    margin: 0;
    border-color: #000;
    height: 60px;
  }
}

.fixed-add-to-cart-component {
  @media (max-width: $break-sm) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 800;
    background-color: #FFF;
    width: 100%;
    margin: 0;
    border-color: #000;
  }

  @media (min-width: 992px) {
      height: 62px;

    .add-to-cart-quantity {
      float: right;
      width: 200px;
      position: relative;
      min-height: 1px;
      padding: 0 15px;
    }
    .add-to-cart-price-btn {
      float: right;
      width: 350px;
      position: relative;
      min-height: 1px;
      padding: 0 15px;
    }
    .add-to-cart-loyalty {
      float: right;
      width: 600px;
      min-height: 24px;
      position: relative;
      padding: 0 15px;

      .loyalty-container {
        display: flex;
        flex-direction: row-reverse;
        position: absolute;
        margin: auto;
        height: 62px;
        right: 0;
      }
      .atc-loyalty {
        padding: 0 10px;
        float: right;
        margin: auto;
      }
      .atc-loyalty:nth-child(1) {
        order: 2;
      }
      .atc-loyalty:nth-child(2) {
        order: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  /* MOBILE + TABLET */
  .add-to-cart-quantity {
    float: left;
    width: 35%;
    position: relative;
    min-height: 1px;
    padding: 0 15px;
  }
  .add-to-cart-price-btn {
    float: right;
    width: 65%;
    position: relative;
    min-height: 1px;
    padding: 0 15px;
  }

  .add-to-cart-loyalty .atc-loyalty {
    float: right;
  }
}

@media (min-width: 992px) {
  /* DESKTOP */
  .add-to-cart-quantity {
    float: left;
    width: 35%;
    position: relative;
    min-height: 1px;
    padding: 0 15px;
  }
  .add-to-cart-price-btn {
    float: right;
    width: 65%;
    position: relative;
    min-height: 1px;
    padding: 0 15px;
  }
  .add-to-cart-loyalty {
    float: right;
    width: 100%;
    position: relative;
    min-height: 1px;
    padding: 0 15px 10px 15px;
  }

}

/* When add to cart is load, put SOS on position left */
#divContainerFluidMessageSOS {
  right: unset !important;
  left: 10px !important;
}

.customizable-item {
  .pdt-icon-custom {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    height: 50px;
    margin-right: 10px;
    float: left;

    &#pdt-icon-txt {
      background-image: url(../../images/web/product/pers-txt.png);
    }
    &#pdt-icon-img {
      background-image: url(../../images/web/product/pers-img.png);
    }
    &#pdt-icon-txt-img {
      background-image: url(../../images/web/product/pers-txt-img.png);
    }
  }

  .pdt-text {
    line-height: 15px;
    font-size: 12px;
    display: inline-block;
    width: 60%;
    vertical-align: middle;
  }

  .pso-text {
    font-size: 10px;
    color: #000;
    float: right;
    margin-right: 20px;

    #sp-txt {
      font-size: inherit;
      color: inherit;
    }
  }
}

.add-to-cart-size-header, .add-to-cart-effect-header, .add-to-cart-header {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  $breakpoint-mobile: 412px;

  #preview-size, #preview-pack, #add-to-cart-preview-effect, .preview {
    /*To switch between mobile and computer*/
    @media (max-width: $breakpoint-mobile) { /*Mobile*/
      text-align: left;
    }
    @media (min-width: $breakpoint-mobile+1) { /*Computer*/
      float: right;
      text-align: right;
      line-height: 2.4;
    }
    margin: -6px 50px 0 0;
    padding: 0;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #add-to-cart-preview-support-color {
    width: 100px;
  }
}

.size-old-price {
  margin-left: 10px;
  text-decoration: line-through;
  color: #b7b7b7 !important;
}

.multi-product-options {
  .previewColorMultiProduct {
    border: 1px solid #000;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    top: 10px;
    display: none;
    margin-top: 10px;
  }
}

.gender-text {
  display: inline;
  line-height: 1.3;
  font-size: 1.1em;
}
.gender-icon {
  display: none;
  margin: -3px 0 0 0;
}
h3.duo-design-title {
  font-size: 16px;
}
@media (max-width: $break-sm) {
  .gender-text {
    display: none;
  }
  .gender-icon {
    display: inline-block;
    margin: -3px 0 0 0;
  }
}

.blind-size {
  &.margin {
    margin-top: 10px;
  }

  .title {
    padding: 0;
    &.border {
      border-top: 1px solid #DDD;
    }
  }
}

#add-to-cart-tissue {
  img {
    margin: auto;
  }

  .tissue-option {
    label {
      @media (min-width: $break-lg) {
        margin-left: calc(100% - 95%);
      }
    }
    .blind-option {
      margin-bottom: 10px;
    }
    .features {
      padding: 0;
      font-weight: bold;
      overflow: hidden;
      white-space: normal;
      font-size: 11px;
      line-height: 1.5;
      i {
        vertical-align: text-top;
      }
    }
  }
}

.short-label {
  width: 80% !important;
}

#add-to-cart-mechanism {
  .mechanism-price-container {
    padding: 8px 25px;
    text-align: right;
  }

  .mechanism-price-container * {
    color: #009587;
  }

  .mechanism-body *, .size12px *, .size12px {
    font-size: 12px;
  }

  .mechanism-price {
    color: #149587;
    font-size: 12px;
    line-height: 3;
  }

  .mechanism-features {
    padding: 0 30px;
  }

  .mechanism-chain-position {
    padding: 0 30px;
    margin-top: 10px;
  }

  .mechanism-control,
  .mechanism-charger {
    padding: 0 30px;
    .qty-display {
      width: 30%;
    }
  }
}

#add-to-cart-drop {
  img {
    margin: auto;
  }
}

.control-warning {
  display: none;
}

#buttonOnlyMobile {
  display: none;
  @media (max-width: $break-sm) {
    display: block;
    #buttonOnlyMobile:first-child {
      background-color: white;
    }
  }
}

.product-popup-options {
  .size-name-composition {
    display: inline-block;
  }

  $options-font-size: 12px;
  $price-color: #009587;
  $old-price-color: #b7b7b7;

  .size-prices, .rate-prices {
    position: absolute;
    left: 60%;
    &.size-prices-composition {
      left: 60%;
    }
    @media (min-width: 320px) {
      left: 70%;
      &.size-prices-composition {
        left: 70%;
      }
    }
    @media (max-width: 363px) {
      &.size-prices-composition {
        left: 0 !important;
        position: relative !important;
        display: block !important;
        margin-left: 90px;
      }
    }
    .size-old-price {
      display: block;
    }
    &.clothing-price {
      left: 51%;
    }
    color: inherit;

    @media (min-width: $break-sm) {
      left: 45%;
      &.size-prices-composition {
        left: 60%;
      }
      .size-old-price {
        display: inline-block;
      }
      &.clothing-price {
        left: 70%;
      }
    }
    .size-price {
      margin-left: 10px;
      color: $price-color;
    }
    .size-old-price {
      margin-left: 10px;
      text-decoration: line-through;
      color: $old-price-color;
    }
    &.for-mechanism {
      left: initial !important;
      right: 5% !important;
    }
  }

  .size-offers {
    float: right;
    margin-right: -10px;
    color: #000 !important;
    font-weight: normal !important;
    text-align: right;

    @media (max-width: 320px) {
      display: none;
    }

    .size-top-seller {
      background-color: #FBBC04;
      color: #000;
      padding: 1px 5px;
      margin-right: 5px;
      font-weight: bold;
      display: inline-block;
    }

    .size-discount {
      background-color: #ff0000;
      color: #FFF !important;
      padding: 1px 5px;
      font-weight: bold;
      display: inline-block;

      &.black-discount {
        background-color: #000;
      }
    }

    .free-ship {
      display: inline-block;
      position: relative;

      img {
        max-height: 20px;
      }

      .free-ship-express {
        position: absolute;
        bottom: -10px;
        left: 0;
        color: #000;
        font-size: 10px;
        font-weight: bold;
      }

      &.clothing-ship {
        margin-right: 0;
      }
    }
  }

  .has-free-ship, .has-discount, .has-top-seller {
    @media (min-width: 321px) and (max-width: $break-sm), (min-width: $break-md) and (max-width: $break-lg) {
      .size-prices {
        display: block;
        position: relative !important;
        left: initial !important;
        top: initial !important;
        right: initial !important;
        margin-top: 5px;
        &.size-prices-composition {
          margin-left: 100px;
        }

        .size-price {
          margin: 0 !important;
        }

        .size-old-price {
          display: inline-block !important;
        }
      }

      .size-offers {
        float: none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .has-discount.has-free-ship {
    .size-top-seller {
      display: block !important;
      margin-right: 0 !important;
      margin-bottom: 3px;
    }
  }
}

/* TODO: review */
.customText {
  border: 1px solid #000 !important;
  padding-left: 5px;
  &.disabled-resize {
    display: block;
    overflow: hidden;
    resize: none;
  }
  &.limit-rows {
    overflow-y: auto;
  }
}