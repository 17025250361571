.product-sprite {
  background-image: url(../../images/web/sprites/product-icons.png);
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 6px;
  width: 29px;
  height: 25px;

  &.sprite-ico_baby {
    /*width: 17px;
    height: 25px;*/
    background-position: -10px -10px;
  }

  &.sprite-ico_color {
    /*width: 25px;
    height: 25px;*/
    background-position: -47px -10px;
  }

  &.sprite-ico_kids {
    /*width: 24px;
    height: 25px;*/
    background-position: -92px -10px;
  }

  &.sprite-ico_man {
    /*width: 13px;
    height: 25px;*/
    background-position: -136px -10px;
  }

  &.sprite-ico_shirt {
    /*width: 26px;
    height: 25px;*/
    background-position: -169px -10px;
  }

  &.sprite-ico_shirt-color {
    /*width: 29px;
    height: 25px;*/
    background-position: -215px -10px;
  }

  &.sprite-ico_size {
    /*width: 25px;
    height: 25px;*/
    background-position: -264px -10px;
  }

  &.sprite-ico_woman {
    /*width: 13px;
    height: 25px;*/
    background-position: -309px -10px;
  }
}