button:disabled, div.loading-status {
  span[class*="mdi-action-"]:before {
    content: '\E61C';
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
@keyframes spin {
  to {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}