#menu-right-container {
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    position: fixed;
    top: 0;
    right: -800px;
    z-index: 99999999;
    background-color: #FFF;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    -ms-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
    border-left:1px solid;
    /*overflow: hidden;*/
}
.menu-right-open {
    visibility: visible!important;
    right: 0 !important;
}
#menu-right-close {
    position: fixed;
    top: 0;
    background-color: #FFF;
    height: 40px;
    width: 40px;
    z-index: 950;
    text-align: center;
    cursor: pointer;
    display: none;
}
#menu-right-close .glyphicon {
    font-size: 25px;
    line-height: 40px;
}
@media (max-width: 767px) {
    /*MOBILE*/
    #menu-right-container {
        width: 80%;
    }
    #menu-right-close {
        right: 80%;
    }
}
@media (min-width: 768px) {
    /*TABLET-DESKTOP*/
    #menu-right-container {
        width: 500px;
    }
    #menu-right-close {
        right: 500px;
    }
}