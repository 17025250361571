$not-in-wishlist-color: #000;
$in-wishlist-color: #B71C1C;

.wishlist {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #FFF;
  border-radius: 15px;
  width: 30px;
  height: 30px;

  .wishlist-icon {
    color: $not-in-wishlist-color;
    font-size: 20px;
    margin: 4.4px;

    &.in-list {
      color: $in-wishlist-color;
    }
  }
}