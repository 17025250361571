.popup-promo-content {
    text-align: center;
}
.popup-promo-content img {
    max-width: 100%;
}
.popup-promo-content h2 {
    margin: 10px 0 0;
}
.popup-promo-content p {
    margin: 10px 0;
}
.popup-promo-content .popup-promo-voucher {
    padding: 10px;
    border: 1px solid #DCDCDC;
    font-weight: bold;
    color: black;
    font-size: 17px;
}
.popup-promo-content .popup-promo-link {
    margin-top: 10px;
}
.popup-promo-content .popup-promo-link a {
    color: #00bfff;
    text-decoration: underline;
}
/*************************************/
.modal-dialog.modal-dialog-centered {
    left: 50% !important;
    top: 50% !important;
    -moz-transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}
#popup-chollazos .popup-body {
    margin: 1rem!important;
    font-size: 15px !important;
    padding-top: 6px !important;
}
#popup-chollazos .popup-title {
    font-size: 18px !important;
    text-align: center!important;
    display: block;
    font-weight: bold;
    color: rgb(33, 37, 41);
    line-height: 27px;
}
#popup-chollazos .popup-text {
    text-align: center!important;
    margin-bottom: 0.75rem!important;
    margin-top: 0.75rem!important;
    color: rgb(33, 37, 41);
    font-size: 15px !important;
    line-height: 27px;
}
#popup-chollazos .go-to-offer {
    padding: 16px;
    text-transform: uppercase!important;
    border-radius: 50rem!important;
    font-weight: 700!important;
}
#popup-chollazos .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
#popup-chollazos .input-group input {
    font-size: 15px !important;
    background-color: #fff;
    opacity: 1;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    color: rgb(33, 37, 41);
    border: 2px solid #000;
    border-radius: 0.25rem 0 0 0.25rem;
    display: block;
    padding: 6px 12px;
    height: min-content;
    line-height: 24px;
}

#popup-chollazos .input-group button {
    margin-left: -1px;
    padding: 6px 12px;
    border-radius: 0 0.25rem 0.25rem 0;
    position: relative;
    z-index: 2;
    border: 2px solid #000;
    font-size: 20px;
    color: #fff;
    box-shadow: none!important;
    background-color: rgb(33, 37, 41);
    line-height: 24px;
}
#popup-chollazos .input-group button.btn-success {
    background-color: #198754;
}
#popup-chollazos .shadow-none i {
    color: #fff;
    font-size: 20px;
    line-height: 21px;
}
@media (min-width: 768px) {
    .modal-dialog.modal-dialog-centered {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
    }
    #popup-chollazos .modal-body {
        padding-bottom: 5rem!important;
    }
    #popup-chollazos .popup-body {
        margin-top: 0!important;
        font-size: 18px !important;
    }
    #popup-chollazos .popup-title {
        font-size: 20px !important;
        text-align: left!important;
    }
    #popup-chollazos .popup-text {
        text-align: left!important;
        font-size: 18px !important;
    }
}