$klarna-bg-color: #f3b6c6;

.klarna-banner {
  background-color: $klarna-bg-color !important;
  text-align: left;
  margin: 0 0 15px;
  padding: 5px 10px;
  line-height: 1.25;

  >div {
    padding: 0 5px;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }

  * {
    color: #000 !important;
    font-size: 13px;
  }

  small {
    font-size: .875em !important;
  }
}