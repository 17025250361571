#footer {
    background-color: #1C1B1B !important;
    clear: both;
}
#footer-social {
    margin-bottom: 10px;
}
#footer-social .tvi-logo {
    color: white;
    font-size: 3em;
}
#social-links {
    float:right;
}
#footer-links * {
    color: #FFF;
    font-size: 13px;
}
#footer-links .footer-title, #footer-links .footer-title a {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
}
#footer-links .footer-link a {
    text-decoration: none;
}
#footer-links .footer-link a:hover {
    color: #696969;
}

.linkSocial {
    background-image: url(../../images/web/sprites/social-links.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 33px;
    height: 33px;
    cursor: pointer;
}

.linkSocial#linkSocialFB{
    background-position: -91px -5px;
}

.linkSocial#linkSocialFB:hover{
    background-position: -5px -48px;
}

.linkSocial#linkSocialBL{
    background-position: -5px -5px;
}

.linkSocial#linkSocialBL:hover {
    background-position: -48px -5px;
}

.linkSocial#linkSocialGP {
    background-position: -48px -48px;

}

.linkSocial#linkSocialGP:hover {
    background-position: -91px -48px;
}

.linkSocial#linkSocialIN {
    background-position: -5px -91px;
}

.linkSocial#linkSocialIN:hover {
    background-position: -48px -91px;
}

.linkSocial#linkSocialPI {
    background-position: -91px -91px;
}

.linkSocial#linkSocialPI:hover {
    background-position: -134px -5px;
}

.linkSocial#linkSocialTW {
    background-position: -134px -48px;
}

.linkSocial#linkSocialTW:hover {
    background-position: -134px -91px;
}

.linkSocial#linkSocialYO {
    background-position: -5px -134px;
}

.linkSocial#linkSocialYO:hover {
    background-position: -48px -134px;
}
#footer-logos>span {
    cursor: pointer;
}
#footer-logos span {
    display: block;
    float: right;
    margin-left: 15px;
}
#footer-logos .img-grey {;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    transition-duration: .2s;
}
#footer-logos span:hover .img-grey {
    filter: none;
    -webkit-filter:none;
    -moz-filter:none;
    -ms-filter:none;
    -o-filter:none;
}

#footer-safety {
    clear: both;
    text-align: right;
    cursor: default;
}
#footer-safety p {
    font-size: 12px;
    margin-top: 10px;
}
.safePaymentHeader {
    line-height: 20px;
    text-transform: uppercase;
    margin-top:25px;
}
.safePaymentHeader span {
    color: black;

}
.safePaymentHeader .safePaymentIcon {
    font-size: 30px;
    vertical-align: sub;
}
.safePaymentHeader .safePaymentText {
    font-weight: bold;
    margin-top: -4px;
}
.workshop-location {
    color: white;
    font-weight: bold;
    font-size: 16px;
    vertical-align: bottom;
    margin-left: 10px;
    text-transform: uppercase;
}

#footer-customers, #footer-aboutus, #footer-stickers, #footer-conditions, #footer-blog, #footer-logos {
    margin-bottom: 40px;
}

@media (max-width: 991px) {
    #social-links {
        float: none;
        margin-top: 20px;
    }
    #footer-social {
        padding-bottom: 50px;
    }
    #footer-links {
        text-align: left;
    }
    #footer-links .footer-title {
        margin-bottom:15px;
    }
    #footer-logos, #footer-safety, .safePaymentHeader {
        text-align: left;
    }
    #footer-logos span {
        display: inline;
        float: none;
        margin-left: inherit;
        margin-top: 20px;
    }
    .workshop-location {
        display: block;
        margin: 10px 0 0 0!important;
    }
}
#footer-safety .lazy-load-img.footer-payment-method {
    min-width: 30px;
    min-height: 30px;
}
@media (max-width: 768px) {
    #footer-safety .footer-payment-method {
        max-height: 30px;
        margin: 7px 0 0 5px;
    }
}
@media (min-width: 769px) {
    #footer-safety .footer-payment-method {
        max-height: 30px;
        margin: 2px 0 0 2px;
    }

    .footer-copyright {
        text-align: right;
    }
}

.whatsapp-icon {
    background-image: url(../../images/web/logos/whatsapp.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 1px;
    width: 13px;
    height: 15px;
}

.social-link {
    display: inline-block;
    cursor: pointer;
    transition: transform .2s;
}
.social-link:hover {
    transform: scale(1.5);
}
@media (max-width: 991px) {
    #footer-links hr {
        display: block;
        margin: 1rem 0 25px;
        color: inherit;
        background-color: currentColor;
        border: 0;
        opacity: 1 !important;
    }
    .footer-copyright {
        text-align: center;
    }

    .footer-social {
        text-align: center;
    }
    #footer-safety {
        padding: 0 0 30px;
    }
}

@media (min-width: 992px) {

    #footer-safety {
        padding: 50px 0 50px;
    }

    #footer-links hr {
        display: none;
    }

    .footer-copyright {
        text-align: left;
    }
    .footer-social {
        text-align: right;
    }

    #footer-social {
        padding-bottom: 60px;
    }

    #footer-links * {
        font-size: 15px;
    }
}

.newsletter-footer .form-check {
    padding-left: 30px;
}

.newsletter-footer .form-check-label {
    color: white !important;
    cursor: pointer;
    font-weight: normal;
    font-size: 12px !important;
    padding-top: 1px;
}

.newsletter-footer .form-check-label a {
    color: white;
    text-decoration: underline;
    font-size: 12px !important;
}

.newsletter-footer .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    font-size: 20px !important;
}

.newsletter-footer .form-check-input:checked {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
    background-color: transparent;
    border-color: white;
}

.footer-copyright {
    font-size: 12px !important;
}