.panel-dropdown {
    margin: 15px 0;
    padding: 15px;
    position: relative;

    .panel-info-button {
        color: #b6b6b5 !important;
        background-color: #fff !important;
        position: absolute;
        top: -12px;
        left: -11px;
        width: 25px;
        height: 25px;
        padding: 0;
        font-size: 25px;
        font-weight: 400;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }

    .panel-dropdown-header {
        line-height: 50px;
        cursor: pointer;
        position: relative;
        margin: -15px 0;
        border-bottom: 1px solid #DDD;

        * {
            color: #000;
        }

        .panel-icon {
            font-size: 25px;
            vertical-align: middle;
            margin-right: 10px;
            float: left;
            line-height: 50px;
        }

        .panel-title {
            line-height: 15px;
            font-size: 12px;
            display: inline-block;
            width: 60%;
            vertical-align: middle;
        }

        .panel-preview-selected {
            position: absolute;
            top: 0;
            right: 30px;
            max-width: 75px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 10px;
        }

        .panel-arrow {
            position: absolute;
            line-height: 50px;
            right: -5px;
            top: 1px;
            display: inline-block;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-family: "Glyphicons Halflings";

            &:before {
                content: "\E113";
            }
        }

        .panel-plus-arrow {
            position: absolute;
            line-height: 50px;
            right: 0px;
            top: 1px;
            display: inline-block;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-family: "Glyphicons Halflings";

            &:before {
                content: "\2212";
            }
        }
    }

    .panel-dropdown-body {
        margin-top: 15px;
        padding-top: 10px;
        width: 100%;
    }

    &.panel-closed {
        .panel-dropdown-header {
            border-bottom: none;

            .panel-arrow {
                &:before {
                    content: "\E114";
                }
            }

            .panel-plus-arrow {
                &:before {
                    content: "\002b";
                }
            }
        }

        .panel-dropdown-body {
            display: none;
        }
    }

    &.panel-no-title {
        line-height: 21px;
        padding: 0;

        .panel-dropdown-body {
            padding: 0;
            margin: 0;
        }
    }
}

.multi-panel-row {
    margin: -15px;
}