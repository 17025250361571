@import "../shared/responsive-vars";
@import "../../sass/website/Tools/animation-blink.scss";

$headerMenuItemImageHeight: 100px;
$headerMenuHighlightLink: #2196f3;

.slide-menu-container {
  margin: auto;
  /*width: 90%;*/
  position: relative;
  overflow: hidden;
  .swiper-menu-container, .swiper-mobile-menu-container {
    margin:0 20px;
    height: 33px;
    .swiper-wrapper {
      position: initial;
    }
    .swiper-slide {
      width: max-content;
      position: initial;
      a {
        text-decoration: none;
        color: #000;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
      }
    }
    .swiper-menu-button-prev, .swiper-menu-button-next,
    .swiper-mobile-menu-button-prev, .swiper-mobile-menu-button-next{
      &:after {
        font-family: Glyphicons Halflings;
        font-style: normal;
        font-weight: 200;
        line-height: 1;
        font-size: 24px;
        color: #000;
        background-color: #fff;
        padding: 0 5px 0 3px;
      }
      &.swiper-button-disabled {
        opacity: 0;
        :after {
          color: #fff;
        }
      }
    }
    .swiper-menu-button-prev, .swiper-mobile-menu-button-prev {
      left: 0;
      &:after {
        content: "\E257" !important;
      }
    }
    .swiper-menu-button-next, .swiper-mobile-menu-button-next {
      right: 0;
      &:after {
        content: "\E258" !important;
      }
    }
  }
}
.header-menu-separator {
  border-top: 1px solid #b2b2b2;
}
#mobile-header-menu-bottom {
  .slide-menu-container {
    line-height: 2;
    /*width: 80%;*/
    .swiper-mobile-menu-button-prev {
      left: -5px;
    }
    .swiper-mobile-menu-button-next {
      right: -5px;
    }
  }
}

#hmb-links.sticky .hmb-links-container {
  top: 34px;
}

#mobile-header-menu-bottom {
  .swiper-slide {
    margin-right: 20px;
  }
}

.header-menu {

  .header-menu-item {
    border-bottom: 1px solid #000;
    text-align: left;
    padding: 15px 0;

    @media (min-width: $break-md) {
      border: none;
      padding: 0;
    }

    .header-menu-item-image {
      display: none;
      width: 100%;
      margin-bottom: 10px;

      @media (min-width: $break-md) {
        display: block;
      }
    }

    .header-menu-item-title {
      font-weight: bold;
      color: #000;
      cursor: pointer;
      margin-top: 8px;
      white-space: normal;

      @media (min-width: $break-md) {
        cursor: default;
      }
    }
    .header-menu-item-text {
      color: #000;
      white-space: normal;
    }

    .header-menu-item-links {
      display: none;
      list-style: none;
      padding: 0;

      @media (min-width: $break-md) {
        display: block !important;
      }

      li {
        padding-top: 10px;

        @media (min-width: $break-md) {
          padding-top: 5px;
        }

        &.header-menu-item-more {
          padding-top: 15px;

          a {
            color: $headerMenuHighlightLink;
            font-style: italic;
            text-decoration: underline;
          }
        }

        a {
          color: #000;
          text-decoration: none;
          -webkit-transition: ease 0.2s;
          -moz-transition: ease 0.2s;
          -ms-transition: ease 0.2s;
          -o-transition: ease 0.2s;
          transition: ease 0.2s;

          &:hover {
            color: $headerMenuHighlightLink;
          }
        }
      }
    }
  }
}