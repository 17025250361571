@import "../shared/responsive-vars";
@import "list-product";

$arrow-color: #000;
$arrow-highlight: #f7ae1c;

.related-container, .search-related-container {
  margin: 20px 0 20px 0;
  
  &.product-related {
    @media (max-width: $break-sm) {
      margin-bottom: 12px;
    }
  }

  .related-title {
    white-space: initial;
    color: #000;
    font-weight: bold;
    font-size: 15px;
    @media (max-width: $break-sm) {
      font-size: 12px;
    }
    text-align: center;
    margin-left: 10px;
    text-transform: uppercase;
  }

  .related-items {
    .related-nav {
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: all .3s ease;
      color: #000;
      opacity: 1;

      &:after {
        font-family: Glyphicons Halflings;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        font-size: 30px;
      }
      &:hover {
        color: #f7ae1c;
      }

      &.swiper-button-next:after {
        content: "\E258" !important;
      }
      &.swiper-button-prev:after {
        content: "\E257" !important;
      }

      &.swiper-button-prev {
        left: 0;
      }

      &.swiper-button-next {
        right: 0;
      }

      @media (max-width: $break-md) {
        &.swiper-button-prev {
          left: -40px;
        }

        &.swiper-button-next {
          right: -30px;
        }
      }

      @media (max-width: $break-sm) {
        height: 100%;
        width: 4%;
        top: 0;
        &.swiper-button-prev {
          left: 4px;
          top: 20px;
        }

        &.swiper-button-next {
          right: 8px;
          top: 20px;
        }
      }

      @media (max-width: 320px) {
        &.swiper-button-prev {
          left: 0;
        }

        &.swiper-button-next {
          right: 0;
        }
      }

      /* Mobile */
      @media (max-width: 320px) {
        width: 15%;
      }

    }
    &.swiper-container {
      margin: 0;
      position: inherit;

      @media (min-width: $break-md) {
        margin: 0 20px;
      }

    }

    .list-product {
      padding: 0 10px;
      &.with-margin-bottom {
        margin-bottom: 0;
      }
    }
  }
}

.related-container:not(.ajax-content) {
  @media (min-width: 320px) {
    min-height: 155px;
  }
  @media (min-width: 375px) {
    min-height: 186px;
  }
  @media (min-width: $break-xs) {
    min-height: 200px;
  }
  @media (min-width: $break-sm) {
    min-height: 200px;
  }
  @media (min-width: $break-md) {
    min-height: 196px;
  }
  @media (min-width: $break-lg) {
    min-height: 270px;
  }
}

.search-related-container {
  position: relative;
  margin: 20px 0 10px 0 !important;
  .related-items {
    &.swiper-container {
      @media (min-width: $break-md) {
        margin: 0 10px !important;
      }
    }
    .related-nav {
      &.swiper-button-prev {
        top: 40%;
        left: -1px !important;
      }

      &.swiper-button-next {
        top: 40%;
        right: -1px !important;
      }
      @media (max-width: $break-sm) {
        &.swiper-button-prev {
          top: 0;
          left: 2px !important;
        }

        &.swiper-button-next {
          top: 0;
          right: 2px !important;
        }
      }
    }
  }
}