#personalized-services-search {
  background-color: #00A2E8;
  color: #FFF;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 30px;

   * {
     color: inherit;
   }

  #pss-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;

    @media (min-width: $break-md) {
      font-size: 35px;
    }
  }

  #pss-text {
    font-weight: bold;

    @media (min-width: $break-md) {
      font-size: 25px;
    }
  }

  .pss-service {
    display: inline-block;
    width: 19%;
    margin: 0 auto 15px;
    padding: 0 5px;
    transition: transform .2s;
    text-decoration: none;

    &:hover {
      transform: scale(1.1);
    }

    .pss-icon {
      max-width: 100px;
      width: 100%;
    }

    .pss-name {
      font-weight: bold;
      margin-top: 10px;
      font-size: 12px;
    }

    &.visible-xs {
      width: 25%;
    }
  }

  #pss-button {
    color: #00A2E8;
    background-color: #FFF;
    font-weight: bold;
    min-width: 50%;
    max-width: 90%;
    white-space: initial;

    @media (min-width: $break-md) {
      font-size: 20px;
    }
  }
}