/* COOKIES STYLE */
#cookieChoiceInfo {
    display: none;
    background-color:#000;
    width:100%;
    z-index:99;
    position: absolute;
}
#cookieChoiceInfo.sticky {
    position: fixed;
}
#cookieChoiceInfo #cookieChoiceText {
    color: #FFF;
}
#cookieChoiceInfo .container {
    padding-top: 15px;
    padding-bottom: 15px;
}
.dismissCookiesX {
    float:right;
    font-size: 50px;
    color: #FFF;
    margin-top: -15px;
    cursor: pointer;
}
button.cookieChoiceButton {
    font-weight: bold;
}

@media (min-width: 992px) {
    #cookieChoiceInfo {
        margin-top: 59px;
    }
    #cookieChoiceInfo.sticky {
        margin-top: -61px;
    }
}
@media (max-width: 991px) {
    #cookieChoiceInfo {
        text-align: center;
        position: fixed;
        top: 90px;
        z-index: 999999;
    }
    #cookieChoiceInfo .container {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .remove-margin-top {

    }
    #breadcrumbDiv.remove-margin-top, .containerFluidWinstickerProduct.remove-margin-top {
        margin-top: 0px;
    }
    #cookieChoiceInfo #cookieChoiceText {
        font-size: 10px;
    }
    #cookieChoiceInfo #cookieChoiceText a {
        font-size: 10px;
    }
    button.cookieChoiceButton {
        font-weight: bold;
        font-size: 13px;
        padding: 6px 12px;
    }
}
/*** Vertical align ***/
.cookieDiv {
    display: inline-block;
    vertical-align: middle;
}
.cookieDiv .btn {
    padding: 8px 8px;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
}
@media (max-width: 991px) {
    .cookieDiv {
        line-height: 1;
    }
}
@media (max-width: 768px) {
    .cookieDiv {
        line-height: 1;
    }
    .cookieDiv .btn {
        padding: 7px 5px;
        font-size: 10px;
    }
}
/**********************/

#modal-cookies .modal-dialog {
    margin: auto 30px;
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
}

#modal-cookies .modal-content {
    border-radius: 0;
    height: 100%;
}

#modal-cookies span {
    color: black;
    display: block;
}

#modal-cookies a, #modal-cookies .btn-link {
    color: #0d6efd;
    text-decoration: underline;
    text-transform: none;
}

#modal-cookies * {
    font-size: 13px;
}

#modal-cookies .tvi-logo-mobile {
    font-size: 52px;
    color: #000;
}

#modal-cookies .btn-purple {
    background-color: #D6236F !important;
    color: white;
    text-transform: none;
    font-weight: bold;
}

#modal-cookies .rounded-pill {
    border-radius: 50rem !important;
}

#modal-cookies button:hover {
    box-shadow: none !important;
}

#modal-cookies .cookieChoiceButton {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000 !important;
}

#modal-cookies .signin-cookies {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #9D9D9D !important;
    color: #9D9D9D !important;
    cursor: pointer;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: bold;
}

#modal-cookies .header {
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 1rem !important;
}

#modal-cookies .text {
    text-align: left !important;
    margin-bottom: 3rem!important;
    color: rgb(33, 37, 41);
}
#modal-cookies .text a {
    text-decoration: none !important;
}
#modal-cookies .title {
    font-weight: bold;
    margin-bottom: 10px;
}
#modal-cookies .title h2{
    font-size: 25px !important;
    text-transform: unset !important;
    color: #000 !important;
}
#modal-cookies .btn {
    text-transform: none !important;
    font-weight: bold;
}
#modal-cookies .accept-all-cookies {
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 0;
}
#modal-cookies .decline-all-cookies {
    color: black;
    background-color: #FFF;
    border: 1px solid black;
    border-radius: 0;
}
#modal-cookies .show-modal-config-cookies {
    margin-bottom: 0;
    text-decoration: underline;
    padding-bottom: 0;
}

#modal-cookies .ok-button {
    margin-bottom: 1rem!important;
}
#modal-cookies .config-button button {
    color: #000 !important;
}
#modal-cookies .ok-button button {
    height: 58px !important;
    width: 100%;
    @media (min-width: 992px) {
        width: 400px !important;
        height: 44px !important;
    }
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    text-transform: unset !important;
    color: #fff !important;
    font-size: 15px !important;
}
@media (min-width: 992px) {
    #modal-cookies .title h2 {
        font-size: 30px !important;
    }
    #modal-cookies .ok-button {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important;
    }

    #modal-cookies .modal-dialog {
        margin: auto;
    }

    #modal-cookies .modal-dialog.modal-lg {
        width: 1140px;
    }

    #modal-cookies .modal-body {
        padding: 25px !important;
    }

    #modal-cookies div.text-center {
        text-align: center;
    }

    #modal-cookies * {
        font-size: 15px;
    }

    /*#modal-cookies .cookieChoiceButton {
        width: 250px;
    }*/
}

#modal-cookies button.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 8px 0 0;
}
#modal-cookies button.close span {
    font-size: 30px;
}

/************************/

#offcanvas-cookies {
    background-color: #FFF !important;
    color: #000 !important;
    height: auto;
    right: 0;
    left: 0;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, .2);
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
}

#offcanvas-cookies .o-header {
    margin: auto;
    max-width: 1920px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

#offcanvas-cookies .o-header .o-title {
    color: #000;
    font-size: 20px;
    margin: 0;
    line-height: 1.5;
    font-weight: bold;
}

#offcanvas-cookies .o-body {
    margin: 0 auto 16px;
    max-width: 1920px;
    padding: 0 16px 16px;
    width: 100%;
}

#offcanvas-cookies .o-body p {
    font-size: 13px;
    color: #000;
    margin-bottom: 16px;
}

#offcanvas-cookies .o-body i {
    font-size: 16px;
    color: #000;
    margin-right: 8px !important;
    vertical-align: middle !important;
}

#offcanvas-cookies .o-body .o-buttons {
    justify-content: center!important;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

#offcanvas-cookies .o-body .o-buttons .ob-left {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    margin-bottom: 8px;
}

#offcanvas-cookies .o-body .o-buttons .ob-right {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    margin-bottom: 8px;
}

#offcanvas-cookies .o-body .o-buttons .accept-all-cookies {
    font-size: 15px;
    cursor: pointer;
    border-radius: 50rem !important;
    background-color: #000;
    font-weight: bold;
    padding: 8px 12px;
    width: 100%;
    display: inline-block;
    line-height: 1.5;
    color: #FFF;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#offcanvas-cookies .o-body .o-explanation {
    font-weight: bold;
}

#offcanvas-cookies .o-body .o-explanation a {
    color: #0d6efd !important;
    font-weight: bold !important;
}

#offcanvas-cookies .o-body .o-buttons .accept-basic-analytics-cookies {
    font-size: 15px;
    cursor: pointer;
    border-radius: 50rem !important;
    background-color: #FFF;
    color: #000;
    font-weight: bold;
    padding: 8px 12px;
    width: 100%;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #000;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#offcanvas-cookies .o-body .o-buttons .show-modal-config-cookies {
    font-size: 13px;
    color: #000;
    background-color: transparent;
    text-decoration: underline;
    padding: 8px 12px;
    width: 100%;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (min-width: 1024px) {
    #offcanvas-cookies .o-body .o-buttons .ob-left {
        width: 25%;
        margin-bottom: 0;
    }
    #offcanvas-cookies .o-body .o-buttons .ob-left.ob-center {
        width: 33.33333333%;
        margin-bottom: 0;
    }

    #offcanvas-cookies .o-body .o-buttons .ob-right {
        width: auto;
        margin-bottom: 0;
    }
}

@media (min-width: 1025px) {
    #offcanvas-cookies .o-body .o-buttons .ob-left.ob-center {
        width: 25% !important;
    }
}

@media (min-width: 992px) {
    /*#offcanvas-cookies {
        padding-top: 24px;
        padding-bottom: 24px;
    }*/

    #offcanvas-cookies .o-header .o-title {
        font-size: 20px;
    }

    #offcanvas-cookies .o-body p {
        font-size: 15px;
    }

    #offcanvas-cookies .o-body .o-buttons .accept-all-cookies {
        font-size: 15px;
        margin-bottom: 0;
    }

    #offcanvas-cookies .o-body .o-buttons .accept-basic-analytics-cookies {
        font-size: 15px;
        margin-bottom: 0;
    }

    #offcanvas-cookies .o-body .o-buttons .show-modal-config-cookies {
        font-size: 15px;
        margin-bottom: 0;
    }
}

#modal-config-cookies .modal-dialog {
    height: calc(100% - 1rem);
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    position: relative;
    width: auto;
    pointer-events: none;
    max-width: 800px;
}

#modal-config-cookies .modal-content {
    max-height: 75%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    padding: 0 8px;
}

#modal-config-cookies .modal-content .modal-header {
    padding: 16px 16px 0;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
}

#modal-config-cookies .modal-content .modal-header p {
    color: #212529;
    font-size: 13px;
    margin-bottom: 16px;
}

#modal-config-cookies .modal-content .modal-header .cc-title {
    font-size: 25px;
    font-weight: bold;
}

#modal-config-cookies .modal-content .modal-body {
    overflow-y: auto;
    padding: 0 24px 16px !important;
}

#modal-config-cookies .modal-content .modal-body .form-check {
    padding-left: 60px;
    min-height: 30px;
    cursor: pointer;
    margin-bottom: 16px;
    display: block;
}

#modal-config-cookies .modal-content .modal-body .form-check *{
    cursor: pointer;
}

#modal-config-cookies .modal-content .modal-body .form-check input {
    margin-left: -60px;
    width: 60px;
    height: 25px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
    float: left;
    margin-top: 3.25px;
    vertical-align: top;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

#modal-config-cookies .modal-content .modal-body .form-check input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-color: #0d6efd;
    border-color: #0d6efd;
}

#modal-config-cookies .modal-content .modal-body .form-check input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5;
}

#modal-config-cookies .modal-content .modal-body .form-check label {
    font-size: 13px;
    color: #212529;
    opacity: 1;
    word-wrap: break-word!important;
    word-break: break-word!important;
    margin: 0 0 0 8px;
    font-weight: normal;
}

#modal-config-cookies .modal-content .modal-body .form-check label p{
    font-size: 13px;
    color: #212529;
    opacity: 1;
    word-wrap: break-word!important;
    word-break: break-word!important;
    font-weight: normal;
    margin: 3px 0 0 0;
}

#modal-config-cookies .modal-content .modal-body .form-check label a{
    font-size: 13px;
    color: #0d6efd;
    text-decoration: underline;
    opacity: 1;
    word-wrap: break-word!important;
    word-break: break-word!important;
    font-weight: normal;
}

#modal-config-cookies .modal-content .modal-body .form-check label .cc-ct {
    font-size: 16px;
    font-weight: bold;
    color: #212529;
    line-height: 1;
}

#modal-config-cookies .modal-content .modal-body .form-check label[for=cookie-configuration-all] .cc-ct {
    font-size: 25px;
}

#modal-config-cookies .modal-content .modal-footer {
    justify-content: center!important;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

#modal-config-cookies .modal-content .modal-footer .cc-fb {
    margin: 4px;
    flex: 0 0 auto;
    width: 100%;
}

#modal-config-cookies .modal-content .modal-footer .cc-fb #save-configuration-cookies {
    font-size: 13px;
    cursor: pointer;
    border-radius: 50rem;
    background-color: #000;
    color: #FFF;
    font-weight: bold;
    padding: 16px 12px;
    width: 100%;
    box-shadow: none;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-transform: none;
    margin: 0;
}

@media (min-width: 992px) {
    #modal-config-cookies .modal-content .modal-header p {
        font-size: 15px;
    }

    #modal-config-cookies .modal-content .modal-header .cc-title {
        font-size: 30px;
    }

    #modal-config-cookies .modal-content .modal-body {
        padding: 0 48px 16px !important;
    }

    #modal-config-cookies .modal-content .modal-body .form-check label,
    #modal-config-cookies .modal-content .modal-body .form-check label p,
    #modal-config-cookies .modal-content .modal-body .form-check label a
    {
        font-size: 15px;
    }

    #modal-config-cookies .modal-content .modal-body .form-check label .cc-ct {
        font-size: 20px;
    }

    #modal-config-cookies .modal-content .modal-body .form-check label[for=cookie-configuration-all] .cc-ct {
        font-size: 30px;
    }

    #modal-config-cookies .modal-content .modal-footer .cc-fb {
        width: 41.66666667%;
    }

    #modal-config-cookies .modal-content .modal-footer .cc-fb #save-configuration-cookies {
        font-size: 15px;
    }
}

#offcanvas-cookies-backdrop {
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}